<template>
  <page :title="title">
    <b-container fluid>
      <b-row>
        <b-col
          v-for="collection in collections"
          :key="collection.name"
          class="mb-4 "
          sm="12"
          md="6"
          lg="3"
        >
          <collection-card :collection="collection"></collection-card>
        </b-col>
      </b-row>
    </b-container>
  </page>
</template>

<script>
import CollectionCard from "../components/CollectionCard.vue";
import Page from "./Page.vue";
import { mapState } from 'vuex';
// import InstaEmbed from "../components/InstaEmbed.vue";
export default {
  data() {
    return {
      title: "collections",
    };
  },
  components: {
    Page,
    CollectionCard,
    // InstaEmbed
  },
  computed: {
    ...mapState(["collections"]),
  }
};
</script>

<style lang="scss" scoped>
</style>
